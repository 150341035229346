import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { auth } from '../services/firebaseConnection'
import { onAuthStateChanged } from 'firebase/auth'

export default function Private({ children }) {
    const [loading, setLoading] = useState(true)
    const [signed, setSigned] = useState(false);

    useEffect(() => {
        async function checkLogin() {
            onAuthStateChanged(auth, (user) => {

                if (user) {
                    const userData = {
                        uid: user.displayName,
                        email: user.email
                    };
                    localStorage.setItem("@detailUser", JSON.stringify(userData))
                    setLoading(false);
                    setSigned(true);
                } else {
                    setLoading(false);
                    setSigned(false);
                }
            })
        }
        checkLogin();
    }, [])

    if (loading) {
        return (
            <div></div>
        )
    }
    if (!signed) {
        return <Navigate to="/login" />
    }
    return children;
}