import { useState, useEffect } from 'react'
import './style.css'
import { Header } from '../../components/Header'
import { Input } from '../../components/Input'
import { MdAddLink } from 'react-icons/md'
import { db } from '../../services/firebaseConnection'
import {
    setDoc,
    doc,
    getDoc
} from 'firebase/firestore'
import { toast } from 'react-toastify'

export default function Networks() {
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [github, setGithub] = useState("")

    useEffect(() => {
        function loadLinks() {
            const docRef = doc(db, "social", "link")
            getDoc(docRef)
                .then((snapshot) => {
                    if (snapshot.data() !== undefined) {
                        setFacebook(snapshot.data().facebook)
                        setInstagram(snapshot.data().instagram)
                        setGithub(snapshot.data().github)
                    }
                })
        }
        loadLinks();
    }, [])
    async function handleSave(event) {
        event.preventDefault();
        setDoc(doc(db, "social", "link"), {
            facebook: facebook,
            instagram: instagram,
            github: github
        })
            .then(() => {
                toast.success("Url salva com sucesso")
            })
            .catch((error) => {
                toast.error(error)
            })
    }
    return (
        <div className="admin-container">
            <Header />

            <h1 className="title-social">Suas redes sociais</h1>
            <form className="form" onSubmit={handleSave}>
                <label className="label">Link do facebook</label>
                <Input
                    placeholder="Digite a url do facebook..."
                    value={facebook}
                    onChange={(event) => setFacebook(event.target.value)}
                />
                <label className="label">Link do instagram</label>
                <Input
                    placeholder="Digite a url do instagram..."
                    value={instagram}
                    onChange={(event) => setInstagram(event.target.value)}
                />
                <label className="label">Link do github</label>
                <Input
                    placeholder="Digite a url do github..."
                    value={github}
                    onChange={(event) => setGithub(event.target.value)}
                />
                <button type="submit" className="btn-register">
                    Salvar links <MdAddLink size={24} color="#fff" /></button>
            </form>
        </div>
    )
}