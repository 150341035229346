import { useState } from "react";
import "./style.css";

import { Logo } from "../../components/Logo";
import { Input } from "../../components/Input";
import { auth } from "../../services/firebaseConnection";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    function handleLogin(event) {
        event.preventDefault();
        if (email === "" || password === "") {
            toast.warn("Preencha todos os campos!");
            return;
        }
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                toast.success("Bem-Vindo de volta chefe!");
                navigate("/admin", { replace: true });
            })
            .catch(() => {
                toast.error("Erro ao fazer o login");
            });
    }

    return (
        <div className="login-container">
            <Logo />
            <form className="form" onSubmit={handleLogin}>
                <Input
                    type="email"
                    placeholder="Digite seu email..."
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />

                <Input
                    type="password"
                    placeholder="*************"
                    autoComplete="on"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />

                <button className="button" type="submit">
                    Acessar
                </button>
            </form>
        </div>
    );
}
