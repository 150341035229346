
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
const firebaseConfig = {
    apiKey: "AIzaSyAXcbPVZyRyiSj10d0dItZhlLx667FVylI",
    authDomain: "bkzinhooo-a1a4d.firebaseapp.com",
    projectId: "bkzinhooo-a1a4d",
    storageBucket: "bkzinhooo-a1a4d.appspot.com",
    messagingSenderId: "139153101187",
    appId: "1:139153101187:web:0fb9d5eb53585bdb02e6b6"
};
const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp);
export { db, auth };