import './style.css'

import { Link } from 'react-router-dom'

export function Logo() {
    return (
        <Link to="/">
            <h1 className="logo">BK<span className="logo-text">zinhooo</span></h1>
        </Link>
    )
}